import React, { useState, createRef, useEffect } from 'react'

type Props = TodoProps & {
  updateTodo: (todo: ITodo) => void
  deleteTodo: (_id: string) => void
}

function Todo({ todo, updateTodo, deleteTodo } : Props) {
  const [editing, setEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>(todo.name);
  const editInput = createRef<HTMLInputElement>();

  useEffect(() => {
    if (editing && editInput.current !== null) {
      editInput.current.focus()
    }
  }, [editInput, editing])

  const onBlurEdit = (e: { currentTarget: { value: string } }): void => {
    const trimmedValue = e.currentTarget.value.trim();
    if (trimmedValue.length > 0) {
      setEditing(false)
      updateTodo({ ...todo, name: trimmedValue })
    } else {
      deleteTodo(todo._id);
    }
  }

  const submitEditText = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      const trimmedValue = e.currentTarget.value.trim();
      if (trimmedValue.length > 0) {
        setEditing(false)
        if (todo.name !== trimmedValue) {
          updateTodo({ ...todo, name: trimmedValue })
        }
      }
    }
  }

  const handleTodoTextEdit = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setText(e.target.value);
  }
  return (
    <li className={editing ? 'editing' : todo.status ? 'completed' : ''}>
      <div className='view'>
        <input className='toggle' type='checkbox' defaultChecked={todo.status} onClick={() => updateTodo({ ...todo, status: !todo.status })} />
        <label onClick={() => setEditing(true)}>{todo.name}</label>
      </div>
      <input
        ref={editInput}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlurEdit(e)}
        className="edit"
        value={text}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTodoTextEdit(e)}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => submitEditText(e)}
      />
    </li>
  )
}

export default Todo
