export const firebaseConfig = {
    apiKey: "AIzaSyDTwsSoorpqro2yQh2Fls-e8NXEBTIGbOY",
    authDomain: "mariaflo-app.firebaseapp.com",
    projectId: "mariaflo-app",
    storageBucket: "mariaflo-app.appspot.com",
    messagingSenderId: "35066008693",
    appId: "1:35066008693:web:904d3ca1ce6adf58e6143c"  
};

export const firebaseConfigTest = {
    apiKey: "AIzaSyC6KXD7LJ-5bFgPL5JND-BBNDMFZ995OfY",
    authDomain: "mariaflo-app-test.firebaseapp.com",
    projectId: "mariaflo-app-test",
    storageBucket: "mariaflo-app-test.appspot.com",
    messagingSenderId: "453469086673",
    appId: "1:453469086673:web:671cb896388a4835ef76f0"
}