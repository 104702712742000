import { initializeApp } from "firebase/app";
import { firebaseConfig, firebaseConfigTest } from './firebase.config';

import { addDoc, collection, deleteDoc, doc, initializeFirestore, onSnapshot, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";


// Initialize Firestore through Firebase
let app = null;
if (process.env.REACT_APP_TEST) {
  app = initializeApp(firebaseConfigTest);
} else {
  app = initializeApp(firebaseConfig);
}

const db = initializeFirestore(app, { ignoreUndefinedProperties: true });

export const subscribeTodos = async (completed: boolean, observer: (todos: ApiDataType) => void) => {

  const q = query(collection(db, "todos"), where('completed', '==', completed), orderBy('completedAt', 'desc'), orderBy('created', 'desc'));
  const unsubscribe = onSnapshot(q, querySnapshot => {
    const todos: ITodo[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      todos.push({
        _id: doc.id,
        name: data.name,
        description: data.description,
        status: data.completed,
        createdAt: data.created,
        completedAt: data.completedAt,
        url: data.url
      });
    });
    observer({ todos });
  });
  return unsubscribe;
}

export const addTodo = async (
  newTodo: string
): Promise<void> => {

  try {
    const docRef = await addDoc(collection(db, "todos"), ({
      name: newTodo,
      description: '.',
      completed: false,
      completedAt: null,
      created: serverTimestamp()
    }));

    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  };
}

export const updateTodo = async (
  todo: ITodo
): Promise<void> => {
  const generatedCompletedAt = todo.status === true && !todo.completedAt;
  try {
    await setDoc(doc(db, "todos", todo._id), {
      name: todo.name,
      description: todo.description,
      completed: todo.status,
      created: todo.createdAt,
      completedAt: todo.completedAt || (generatedCompletedAt && serverTimestamp()) || null,
      url: todo.url
    });
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  };
}

export const deleteTodo = async (
  _id: string
): Promise<void> => {

  try {
    await deleteDoc(doc(db, "todos", _id));
  } catch (error) {
    console.error("Error adding document: ", error);
    throw error;
  };

}
