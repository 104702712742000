import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addTodo, deleteTodo, subscribeTodos, updateTodo } from "../API";
import TodoItem from "./TodoItem";

const TodoList = () => {

    const [todos, setTodos] = useState<ITodo[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentText, setCurrentText] = useState<string>("");

    useEffect(() => {
        const unsubscribe = subscribeTodos(false, (neueTodos) => {
            setTodos(neueTodos.todos);
        });
        async function desctruct() {
            (await unsubscribe)();
        }
        return () => { desctruct() };
    }, [])

    const handleSaveTodo = (newTodo: string): void => {
        if (!!newTodo) {
            addTodo(newTodo)
                .catch((err) => console.log(err))
        }
    }
    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentText("");
    }

    const handleUpdateTodo = (todo: ITodo): void => {
        updateTodo(todo)
            .catch((err) => console.log(err))
    }

    const handleDeleteTodo = (_id: string): void => {
        deleteTodo(_id)
            .catch((err) => console.log(err))
    }

    return (
        <section className='todoapp'>
            <header className='header'>
                <Link to="/lookback"><h1 data-testid="headline" style={{color: "#7B0707"}}>Maria & Flo</h1></Link>
            </header>
            <section className='main'>
                <ul className='todo-list'>
                    {todos.map((todo: ITodo) => (
                        <TodoItem
                            key={todo._id}
                            updateTodo={handleUpdateTodo}
                            deleteTodo={handleDeleteTodo}
                            todo={todo}
                        />
                    ))}
                </ul>
            </section>
            <footer className='footer'>
                <span className='todo-count'>{todos.length} Einträge</span>
            </footer>
            <Fab color="primary" aria-label="add" style={{ alignContent: 'right', right: 20, bottom: 20, position: 'fixed' }} onClick={() => { setDialogOpen(true) }}>
                <Add />
            </Fab>
            <Dialog open={dialogOpen} onClose={handleDialogClose} >
                <DialogTitle>Was geht ab?</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={true}
                        margin="dense"
                        id="name"
                        label=""
                        fullWidth={true}
                        variant="standard"
                        onChange={(e) => { setCurrentText(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Abbrechen</Button>
                    <Button onClick={() => { handleSaveTodo(currentText); handleDialogClose(); }}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>


        </section>
    );
}

export default TodoList;
