import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { subscribeTodos, updateTodo } from "../API";

import { Masonry } from "@mui/lab";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const LookBack = () => {
  const [todos, setTodos] = useState<ITodo[]>([]);

  useEffect(() => {
    const unsubscribe = subscribeTodos(true, (neueTodos) => {
      setTodos(neueTodos.todos);
    });
    async function desctruct() {
      (await unsubscribe)();
    }
    return () => { desctruct() };
  }, [])

  useEffect(() => {
    async function download() {
      const somethingMissing = todos.filter(todo => todo.url && !todo.fullUrl).length > 0;
      if (somethingMissing) {
        const todosNeu = await Promise.all(todos.map(async todo => {
          if (todo.url && !todo.fullUrl) {
            try {
              const storage = getStorage();
              const reference = ref(storage, todo.url);
              todo.fullUrl = await getDownloadURL(reference);
            } catch (e) {
              console.error(e);
              todo.fullUrl = "error.jpg";
            }
          }
          return todo;
        }));
        setTodos(todosNeu);
      }
    }
    download();
  }, [todos])

  const handleFileSelect = (todo: ITodo, e: any) => {
    const file = e.target!.files![0];
    var storage = getStorage();
    var storageRef = ref(storage, todo._id);
    uploadBytes(storageRef, file, { cacheControl: "private,max-age=259200" }).then(() => {
      updateTodo({ ...todo, url: todo._id })
    });
  };

  return (
    <section className='todoapp'>
      <header className='header'>
        <Link to="/"><h1 data-testid="headline" style={{color: "#7B0707"}}>Rückblick</h1></Link>
      </header>
      <section className='main'>
        <ul className='todo-list'>
          <Masonry columns={2}>
            {todos.map((todo: ITodo) => (
              <label key={todo._id}>
                <ImageListItem key={todo._id} >
                  <img
                    src={todo.fullUrl || "default.jpg"}
                    alt={todo.name}
                    loading="lazy"
                  />
                  <ImageListItemBar sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }} position="top"
                    title={todo.name}
                  />
                </ImageListItem>
                <input type="file" id="file" onChange={handleFileSelect.bind(this, todo)} accept="image/*" hidden={true} />
              </label>
            ))}
          </Masonry>
        </ul>
      </section>
    </section>
  )
}

export default LookBack
