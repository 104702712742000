import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginUi from './components/LoginUi';
import LookBack from './components/LookBack';
import TodoList from './components/TodoList';

const App = () => {

  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    if (!isSignedIn) {
      onAuthStateChanged(getAuth(), (user) => {
        setIsSignedIn(!!user)
      });
    }
  })
  const theme = createTheme({
    palette: {
      primary: {
        main: '#7B0707',
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {!isSignedIn && <Route path='*' element={<LoginUi />} />}
          {isSignedIn && <Route path="/" element={<TodoList />} />}
          {isSignedIn && <Route path="/lookback" element={<LookBack />} />}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
