import React from 'react';
import { createRoot } from 'react-dom/client';
import '../node_modules/todomvc-app-css/index.css';
import '../node_modules/todomvc-common/base.css';
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
