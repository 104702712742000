
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

import { useEffect } from 'react';
import { firebaseConfig, firebaseConfigTest } from '../firebase.config';

if (process.env.REACT_APP_TEST) {
    firebase.initializeApp(firebaseConfigTest);
} else {
    firebase.initializeApp(firebaseConfig);
}

var ui = new firebaseui.auth.AuthUI(firebase.auth());

const LoginUi = () => {

    useEffect(() => {
        ui.start('#firebaseui-auth-container', {

            signInOptions: [
                {
                    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                    defaultCountry: 'DE',
                }
            ],
            signInSuccessUrl: "/",
        });
    })

    return (<div id="firebaseui-auth-container"></div>)
}

export default LoginUi
